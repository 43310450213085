import "./App.css";
import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ThemeContext } from "./contexts/themeContext";
import logo from "./assets/career/EHawkers_Gradient_WhiteBG.png";
import { Suspense, lazy } from "react";

const HomePage = lazy(() => import("./pages/homePage/HomePage"));
// const HomePage = React.lazy(() => import('./pages/homePage/HomePage'));
const AboutPage = lazy(() => import("./pages/aboutPage/AboutPage"));
const SocialMedia = lazy(() =>
  import("./pages/socialMediaPage/socialMediaPage")
);
const ServicePage = lazy(() => import("./pages/servicePage/ServicePage"));
const SEOPage = lazy(() => import("./pages/seoPage/SEOPage"));
const PerformancePage = lazy(() =>
  import("./pages/performanceMarketingPage/PerformancePage")
);
const DigitalMarketingPage = lazy(() =>
  import("./pages/digitalMarketingPage/digitalPage")
);

const WebsitePage = lazy(() => import("./pages/websitePage/websitePage"));
const ContactUsPage = lazy(() => import("./pages/contactUsPage/ContactUsPage"));
const BrandPage = lazy(() => import("./pages/brandPage/brandPage"));
const ExpertsPage = lazy(() => import("./pages/expertsPage/ExpertsPage"));
const CareerPage = lazy(() => import("./pages/CareerPage/Career"));
const SingleJob = lazy(() => import("./pages/SingleJob/Single"));
const ClientDetails = lazy(() => import("./pages/ClientDetails/ClientDetails"));
//done
function App() {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={darkMode ? "App" : "App-light"}>
      <Suspense
        fallback={
          <div
            className="AppMain"
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                height: "330px",
                width: "270px",
              }}
              src={logo}
              alt=" Loading..."
            />
          </div>
        }
      >
        <div className="blur" style={{ top: "25%", left: "36%" }}></div>
        <div className="boxes">
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
          <div className="box"></div>
        </div>

        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/seo" element={<SEOPage />} />
            <Route path="/performanceMarketing" element={<PerformancePage />} />
            <Route path="/socialMedia" element={<SocialMedia />} />
            <Route
              path="/digitalMarketing"
              element={<DigitalMarketingPage />}
            />
            <Route path="/web" element={<WebsitePage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/brand" element={<BrandPage />} />
            <Route path="/experts" element={<ExpertsPage />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/single/:id" element={<SingleJob />} />
            <Route path="/ClientDetails" element={<ClientDetails />} />
          </Routes>
        </Router>
        <ToastContainer />
      </Suspense>
    </div>
  );
}

export default App;
